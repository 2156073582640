<template>
  <div class="wap-order">
    <div class="top-tab">
      <Tabs color="#1552f0" @click="changeTab">
        <Tab :title="$t('quan-bu')"></Tab>
        <Tab :title="$t('dai-cai-gou')"></Tab>
        <Tab :title="$t('yi-cai-gou')"></Tab>
      </Tabs>
    </div>
   
    

    <div class="order-list">
      <List
        v-if="orderDataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="
          isInit && orderDataList.length == 0 ? '' : $t('common.noMore')
        "
        @load="onLoad"
      >
        <div
          class="list"
          v-for="(item, i) in orderDataList"
          :key="i"
          @click="toDetail(item)"
        >
          <div class="title">{{ item.number }}</div>
          <div class="line">
            <Icon name="notes-o" />
            <span>{{ $t('xia-dan-ri-qi') }}</span>
            <span>{{ item.CreatedAt }}</span>
          </div>
          <div class="line">
            <Icon name="debit-pay" />
            <span>{{ $t('zhi-fu-zhuang-tai-2') }}</span>
            <span class="blue">{{ getStatusName(item.Status) }}</span>
          </div>
          <div class="line">
            <Icon name="cart-o" />
            <span>{{ $t('cai-gou-zhuang-tai-1') }}</span>
            <span>{{ $t('yi-cai-gou') }}</span>
          </div>
          <div class="line">
            <Icon name="logistics" />
            <span>{{ $t('wu-liu-zhuang-tai-2') }}</span>
            <span>{{ $t('mai-jia-yi-qian-shou-0') }}</span>
          </div>

          <div class="price flex-center-between">
            <div>${{ item.TotalAmount }}</div>
            <div>
              <Button
                type="info"
                size="small"
                @click.stop="toPay(item)"
                v-if="item.Status == 1"
                >{{ $t('cai-gou') }}</Button
              >
            </div>
          </div>
          <div class="desc">
            (
            {{ $t('li-run-0') }}
            ${{ item.Income }}
            )
          </div>
        </div>
      </List>

      <Empty v-if="orderDataList.length == 0" :description="$t('zan-wu-shu-ju')"></Empty>
    </div>

    <Popup v-model="showPopup" @close="cancel" round>
      <div class="popup-option-list">
        <div
          class="list"
          v-for="(item, i) in payStatusList"
          :key="i"
          @click="choosePayStatus(item)"
        >
          {{ item.label }}
          <i class="el-icon-check" v-if="item.value === form.payStatus"></i>
        </div>
      </div>
    </Popup>

    <Popup v-model="showShippingPopup" @close="cancel" round>
      <div class="popup-option-list">
        <div
          class="list"
          v-for="(item, i) in shippingStatusList"
          :key="i"
          @click="chooseShippingStatus(item)"
        >
          {{ item.label }}
          <i class="el-icon-check" v-if="item.value === form.payStatus"></i>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup, Tabs, Tab, Icon, Empty, List, Button } from 'vant'
import { orderList, pickupPay } from '@/api/shop'
export default {
  components: {
    Popup,
    Tabs,
    Tab,
    Icon,
    Empty,
    List,
    Button
  },
  data() {
    return {
      orderDataList: [],
      page: {
        page: 1,
        limit: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      showPopup: false,
      showShippingPopup: false,
      shippingName: '',
      payStatusName: '',
      form: {
        status: null,
        payStatus: '',
        shippingStatus: ''
      },
      payStatusList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: '1',
          label: this.$t('wei-zhi-fu')
        },
        {
          value: '2',
          label: this.$t('yi-zhi-fu')
        }
      ],
      shippingStatusList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: '1',
          label: this.$t('ding-dan-yi-qu-xiao')
        },
        {
          value: '2',
          label: this.$t('deng-dai-mai-jia-fu-kuan')
        },
        {
          value: '3',
          label: this.$t('mai-jia-yi-fu-kuan')
        },
        {
          value: '4',
          label: this.$t('gong-ying-shang-yi-jie-dan')
        },
        {
          value: '5',
          label: this.$t('wu-liu-yun-shu-zhong')
        },
        {
          value: '6',
          label: this.$t('mai-jia-yi-qian-shou-0')
        },
        {
          value: '7',
          label: this.$t('ding-dan-yi-wan-cheng')
        },
        {
          value: '8',
          label: this.$t('yi-tui-kuan')
        }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      orderList({
        current: this.page.page,
        pageSize: this.page.limit,
        PurchaseStatus: this.form.status,
      })
        .then((res) => {
          if (isAdd) {
            this.orderDataList = this.orderDataList.concat(res.data.Items)
          } else {
            this.orderDataList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.page) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    changePayStatus() {
      this.showPopup = true
    },
    changeShippingStatus() {
      this.showShippingPopup = true
    },
    cancel() {
      this.showShippingPopup = false
      this.showPopup = false
    },
    choosePayStatus(data) {
      this.form.payStatus = data.value
      this.showPopup = false
      this.payStatusName = data.label
    },
    chooseShippingStatus(data) {
      this.form.shippingStatus = data.value
      this.showShippingPopup = false
      this.shippingName = data.label
    },
    changeTab(e) {
      this.page.current = 1
      this.form.status = e === 0 ? null : e === 1 ? 1 : 2
      this.init()
    },
    toDetail(data) {
      sessionStorage.setItem('orderDetail', JSON.stringify(data))
      this.$router.push({
        name: 'wapOrderDetail'
      })
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-fu-kuan')
          break
        case '1':
          name = this.$t('dai-fa-huo')
          break
        case '2':
          name = this.$t('dai-shou-huo')
          break
        case '3':
          name = this.$t('dai-ping-jia')
          break
        case '4':
          name = this.$t('yi-wan-cheng')
          break
        case '5':
          name = this.$t('yi-qu-xiao')
          break
        case '6':
          name = this.$t('yi-guan-bi')
          break
        case '7':
          name = this.$t('tui-huo-zhong')
          break
        case '8':
          name = this.$t('tui-kuan-cheng-gong')
          break
        case '9':
          name = this.$t('yi-ping-jia')
          break
      }
      return name
    },
    toPay(data) {
      sessionStorage.setItem('orderDetail', JSON.stringify(data))
      this.$router.push({
        name: 'wapOrderPay'
      })
    }
  }
}
</script>